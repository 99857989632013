import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Card, CardContent, CardMedia, makeStyles, Typography, IconButton, Grid } from '@material-ui/core';
import Img from 'gatsby-image'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles(theme => ({
    root: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: theme.spacing(5),
    
      },
    carousel: {
        maxWidth: '100%',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        padding: '10px'
    },
    card: {
        display: 'flex',
        flex: '1 0 25%',
        marginBottom: theme.spacing(5),
        flexDirection: 'column',
        height: '100%',
        margin: '5px'




    },
    cover: {
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'auto',

        }
    },
    imageWrapper: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: '300px'

        }
    },
    sliderWrapper: {
        position: 'relative;',
        margin: 'auto;'
    },
    buttonNext: {
        position: 'absolute;',
        top: '50%;',
        right: '0;',
        transform: 'translateY(-50%);'
    },
    buttonBack: {
        position: 'absolute;',
        top: '50%;',
        left: '0;',
        transform: 'translateY(-50%);'
    },
    promoHeader: theme.typography.promoHeader
}))

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Typography variant='body1' paragraph>{children}</Typography>,
    },
}

const Header = ({title}) => {
    const classes = useStyles()
return (
    <Typography className={classes.promoHeader} align='center'>
        {title}
    </Typography>
)
}
const CustomCard = ({ data }) => {
    const classes = useStyles()
    return (
        <Card className={classes.card} square elevation={0}>
            <CardMedia className={classes.imageWrapper}>
                <Img fluid={data.fotograf.localFile.childImageSharp.fluid} className={classes.cover} />

            </CardMedia>
            <div>
                <CardContent>
                    <Typography component="h5" variant="h5" gutterBottom>
                        {data.icerik}

                    </Typography>

                    {documentToReactComponents(JSON.parse(data.faydalari.raw), options)}

                </CardContent>
            </div>
        </Card>
    )
}


const RAW_MTRLS = ({ data, location }) => {
    const [windowWidth, setWindowWidth] = React.useState(1281)
    const [visibleSlides, setVisibleSlides] = React.useState(4)
    function handleResize() {
        setWindowWidth(window.innerWidth)
    }
    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth < windowWidth) { handleResize(); }
            window.addEventListener('resize', handleResize)
            if (windowWidth <= 768) {
                setVisibleSlides(1)
            }
            if (windowWidth > 768 & windowWidth <= 1280) {
                setVisibleSlides(2)
            }
            if (windowWidth > 1280) {
                setVisibleSlides(4)
            }
            
        }
        return () => window.removeEventListener('resize', handleResize)
    }, [windowWidth])

   
    const classes = useStyles()
    const ingredients = data.allContentfulIcerik.edges
    return (
        <Layout nodeLocale='tr-TR' location={location}>
            <SEO lang='tr-TR' title='Kediler için birbirinden lezzetli kuru mamalarımız' location={location} />
            <Grid  container className={classes.root}>
                <Grid item xs={12}> <Header title='İÇERİKLER'/></Grid>
           
            <Grid item xs={12}>          
            <CarouselProvider
                naturalSlideWidth={100}
                /*naturalSlideHeight={200} */
                totalSlides={ingredients.length}
                visibleSlides={visibleSlides}
                infinite={true}
                isIntrinsicHeight={true}

            >
                 
                <div className={classes.sliderWrapper}>
                    
                    <Slider className={classes.carousel}>
                        {
                            ingredients.map((ingredient, index) => {
                                return (
                                    <Slide key={ingredient.node.id} index={index}>
                                        <CustomCard

                                            data={ingredient.node}

                                        />
                                    </Slide>

                                )
                            })
                        }

                    </Slider>
                    <IconButton component={ButtonBack} className={classes.buttonBack}><ArrowBackIosIcon fontSize='large' /></IconButton>
                    <IconButton component={ButtonNext} className={classes.buttonNext}><ArrowForwardIosIcon fontSize='large' /></IconButton>
                </div>
               
            </CarouselProvider>
            </Grid> 
            </Grid>
        </Layout>
    )
}

export const query = graphql`
query MyQuery {
    allContentfulIcerik (filter: {node_locale: {eq: "tr-TR"}}){
      edges {
        node {
          faydalari {
            raw
          }
          icerik
          node_locale
          id
          fotograf {
            localFile {
                childImageSharp {
                  fluid(maxWidth:400, quality:100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    }
  }

`

export default RAW_MTRLS